/* STEPPER  */

.register-stepper {
  display: table;
  width: 100%;
  margin: 0 auto;
}
.register-stepper .step {
  display: table-cell;
  position: relative;
  padding: 1.5rem;
  z-index: 2;
  width: 25%;
}
.register-stepper .step:last-child .step-bar-left,
.register-stepper .step:last-child .step-bar-right {
  display: none;
}
.register-stepper .step .step-circle {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  border: 2px solid #ee7c36;
}
.register-stepper .step.done .step-circle {
  background-color: #ee7c36;
  border: 2px solid #ee7c36;
  color: #ffffff;
}
.register-stepper .step.done .step-circle:before {
  font-weight: 300;
  content: '\2713';
}
.register-stepper .step.done .step-circle * {
  display: none;
}
.register-stepper .step.done .step-title {
  color: #ee7c36;
}
.register-stepper .step.editing .step-circle {
  background: #ffffff;
  border-color: #ee7c36;
  color: #ee7c36;
}
.register-stepper .step.editing .step-title {
  color: #ee7c36;
}
.register-stepper .step .step-title {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.register-stepper .step .step-title {
  text-align: center;
}

.register-stepper .step .step-bar-left,
.register-stepper .step .step-bar-right {
  position: absolute;
  top: calc(2rem + 5px);
  height: 5px;
  background-color: #d9e2ec;
  border: solid #d9e2ec;
  border-width: 2px 0;
}
.register-stepper .step .step-bar-left {
  width: calc(100% - 2rem);
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
}
.register-stepper .step .step-bar-right {
  width: 0;
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
  transition: width 500ms ease-in-out;
}
.register-stepper .step.done .step-bar-right {
  background-color: #ee7c36;
  border-color: #ee7c36;
  z-index: 3;
  width: calc(100% - 2rem);
}

.dark .register-stepper .step.editing .step-circle {
  background-color: #333;
}

.dark .register-stepper .step .step-bar-left,
.dark .register-stepper .step .step-bar-right {
  background-color: #333;
  border: solid #333;
}
