.slider {
  position: relative;
}

.slider__close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.slider__close .icon {
  font-size: 9px;
  width: 10px;
  fill: #11142d;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.slider__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.slider__container {
  margin: 0 -16px;
}

.slider__item {
  position: relative;
  margin: 0 16px;
  padding: 32px 32px 64px;
  border-radius: 24px;
  overflow: hidden;
}

.slider__wrap {
  position: relative;
  z-index: 2;
  color: #ffffff;
  text-align: left;
}

.slider__date {
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.slider__title {
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.5px;
  font-weight: 500;
  margin-top: 15px;
}

.slider__info {
  margin-bottom: 24px;
  font-family: 'Poppins', sans-serif;
}

.slider__btn {
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.152444);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.152444);
}

.slider__preview {
  position: absolute;
}

.slider__preview img {
  width: 100%;
}

.slider .owl-dots {
  position: absolute;
  left: 48px;
  bottom: 32px;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider .owl-carousel button.owl-dot {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.slider .owl-carousel button.owl-dot.active {
  background: #ffffff;
}

.slider .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 4px;
}

.slider__item {
  background-image: linear-gradient(
    110deg,
    rgba(238, 124, 54, 1) 46%,
    rgba(252, 222, 37, 1) 100%
  );
  background-repeat: 'no-repeat';
  background-size: 'cover';
}

.slider_home .slider__item {
  min-height: 393px;
  /* background: -webkit-radial-gradient(
    0% 0%,
    103.03% 103.03%,
    #d080ff 0%,
    #6c5dd3 100%
  );
  background: -o-radial-gradient(
    0% 0%,
    103.03% 103.03%,
    #d080ff 0%,
    #6c5dd3 100%
  );
  background: radial-gradient(
    103.03% 103.03% at 0% 0%,
    #d080ff 0%,
    #6c5dd3 100%
  ); */
}

.slider_home .slider__wrap {
  max-width: 260px;
}

.slider_home .slider__preview {
  left: 30%;
  bottom: -157px;
  width: 740px;
}

.slider_promotions .slider__item {
  min-height: 508px;
  background: -webkit-radial-gradient(
    100% 2.73%,
    51.4% 51.4%,
    #d080ff 0%,
    #6c5dd3 100%,
    #6c5dd3 100%
  );
  background: -o-radial-gradient(
    100% 2.73%,
    51.4% 51.4%,
    #d080ff 0%,
    #6c5dd3 100%,
    #6c5dd3 100%
  );
  background: radial-gradient(
    51.4% 51.4% at 100% 2.73%,
    #d080ff 0%,
    #6c5dd3 100%,
    #6c5dd3 100%
  );
}

.slider_home .slider__preview.lottie__money {
  left: 90px;
  bottom: -53px;
  width: 740px;
}

.slider_home .slider__preview.lottie_money_2 {
  left: 91px;
  bottom: 60px;
  width: 740px;
}

.slider_promotions .slider__wrap {
  max-width: 310px;
}

.slider_promotions .slider__preview {
  left: 30%;
  bottom: -16px;
  width: 790px;
}

.slider:not(:last-child) {
  margin-bottom: 64px;
}

.dark .slider__close {
  background: #242731;
}

.dark .slider__close .icon {
  fill: #ffffff;
}

.carousel .slider-wrapper {
  padding-top: 20px;
  padding-bottom: 40px;
}

.categories .react-multi-carousel-list {
  padding-top: 20px;
  padding-bottom: 40px;
}
