* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.server__down {
  display: grid;
  place-items: center;
}

.server__down span {
  color: #ee7c36;
}

.server__down p {
  max-width: 520px;
  text-align: center;
  padding: 10px 10px 20px 10px;
  font-family: 'Poppins', sans-serif;
}
.server__down img {
  max-width: 520px;
}

.server__down .h2 {
  max-width: 620px;
  text-align: center;
  padding: 30px 0px 0px 0px;
}

.server__down button {
  margin-bottom: 30px;
}
