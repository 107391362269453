/* custom select  */
.css-26l3qy-menu {
  border-radius: 20px !important;
  overflow: hidden;
  font-size: 12px;
}

.css-yt9ioa-option {
  padding: 3px !important;
}

.css-1uccc91-singleValue {
  font-weight: 600;
  font-size: 12px;
}

.dark .css-26l3qy-menu {
  background-color: #1f2128;
  color: #fff;
}

.dark .css-yk16xz-control {
  background-color: #1f2128;
  color: #fff;
  border-color: rgba(228, 228, 228, 0.1);
}

.price__custom_select {
  width: 20%;
}

.dark .css-1uccc91-singleValue {
  color: #fff !important;
}

.dark .css-1pahdxg-control {
  background-color: #1f2128;
  color: #fff;
  border-color: #ee7c36;
}

.order_book_custom_select .css-yk16xz-control,
.order_book_custom_select .css-1pahdxg-control {
  border: none;
  background: rgba(228, 228, 228, 0.3);
  padding: 12px;
  border-radius: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191 !important;
  font-size: 10px !important;
}

.widgets__select .css-1pahdxg-control,
.widgets__select .css-yk16xz-control {
  background: rgba(228, 228, 228, 0.3);
  padding: 12px;
}

.signup_dropdown {
  text-transform: uppercase;
  color: #3f3f3f;
}

.notifications__select .css-yk16xz-control,
.signup_dropdown .css-yk16xz-control,
.widgets__select .css-yk16xz-control,
.categories__select .css-yk16xz-control,
.market__select .css-yk16xz-control,
.widgets__select .css-yk16xz-control,
.notifications__select .css-1pahdxg-control,
.signup_dropdown .css-1pahdxg-control,
.setting_select .css-1pahdxg-control,
.signup_dropdown .css-1pahdxg-control,
.categories__select .css-1pahdxg-control,
.market__select .css-1pahdxg-control,
.setting_select .css-yk16xz-control {
  border: none;
  background: rgba(228, 228, 228, 0.3);
  padding: 12px;
  border-radius: 12px;
  color: #808191 !important;
  font-size: 12px !important;
}

.order_book_custom_select .css-1uccc91-singleValue {
  text-transform: uppercase;
  color: #808191 !important;
  font-size: 10px !important;
}

.notifications__select .css-1uccc91-singleValue,
.setting_select .css-1uccc91-singleValue,
.widgets__select .css-1uccc91-singleValue,
.market__select .css-1uccc91-singleValue,
.categories__select .css-1uccc91-singleValue {
  color: #808191;
  font-size: 14px;
}

.signup_dropdown .css-1uccc91-singleValue {
  color: #808191 !important;
  font-size: 12px;
}

.dark .order_book_custom_select .css-yk16xz-control,
.dark .order_book_custom_select .css-1pahdxg-control,
.dark .notifications__select .css-yk16xz-control,
.dark .categories__select .css-yk16xz-control,
.dark .market__select .css-yk16xz-control,
.dark .notifications__select .css-1pahdxg-control,
.dark .categories__select .css-1pahdxg-control,
.dark .market__select .css-1pahdxg-control,
.dark .widgets__select .css-1pahdxg-control,
.dark .widgets__select .css-yk16xz-control,
.dark .setting_select .css-yk16xz-control,
.dark .setting_select .css-1pahdxg-control {
  background: #191b20;
  color: #ffffff;
}

.dark .signup_dropdown .css-yk16xz-control {
  background: rgba(228, 228, 228, 0.03);
  color: #808191 !important;
  font-size: 12px;
}

.notifications__select {
  margin-top: 5px;
}
