.icon-activity {
  width: 0.95em;
  height: 1em;
  fill: #808191;
}

.icon-arrow-down-square i {
  width: 0.5em;
  height: 0.5em;
  color: #808191;
}

.icon-arrow-down {
  width: 1.5em;
  height: 1em;
  fill: #11142d;
}

.icon-arrow-right {
  width: 1.45em;
  height: 1em;
  fill: #1b1d21;
}

.icon-arrow-up-right-square {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-arrow-up-right {
  width: 1em;
  height: 1em;
  fill: #11142d;
}

.icon-award {
  width: 0.63em;
  height: 1em;
  fill: #1b1d21;
}

.icon-calendar {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-chart {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-check {
  width: 1.14em;
  height: 1em;
  fill: #11142d;
}

.icon-clock {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: #11142d;
}

.icon-copy {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-deposit {
  width: 1.06em;
  height: 1em;
  fill: #000;
}

.dark.icon-deposit {
  width: 1.06em;
  height: 1em;
  fill: #fff;
}

.icon-discount {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-document-plus {
  width: 0.89em;
  height: 1em;
  fill: #808191;
}

.icon-document-two-lines {
  width: 0.89em;
  height: 1em;
  fill: #808191;
}

.icon-document {
  width: 0.95em;
  height: 1em;
  fill: #808191;
}

.icon-dots {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-home {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-info-circle {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-info-square {
  width: 1.07em;
  height: 1em;
  fill: #11142d;
}

.icon-link {
  width: 1.14em;
  height: 1em;
  fill: #11142d;
}

.icon-notification i {
  width: 0.91em;
  height: 1em;
  color: #808191;
}

.icon-ranking {
  width: 1.14em;
  height: 1em;
  fill: initial;
}

.icon-search i {
  width: 0.5em;
  height: 0.5em;
  color: #808191;
}

.icon-settings {
  width: 0.91em;
  height: 1em;
  fill: #808191;
}

.icon-share {
  width: 1.06em;
  height: 1em;
  fill: #11142d;
}

.icon-star-fill {
  width: 1.06em;
  height: 1em;
  fill: #11142d;
}

.icon-star {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-swap {
  width: 1.08em;
  height: 1em;
  fill: #808191;
}

.icon-theme-dark {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-theme-light {
  width: 1.04em;
  height: 1em;
  fill: #808191;
}

.icon-time-clock {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-verify {
  width: 1.06em;
  height: 1em;
  fill: #1b1d21;
}

.icon-wallet {
  width: 1.1em;
  height: 1em;
  fill: #808191;
}

.icon-withdraw {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon {
  width: 20px;
}

.status {
  position: relative;
  padding-left: 18px;
  line-height: 16px;
  font-weight: 700;
}

.status:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
}

.status.positive {
  color: #4fbf67;
}

.status.positive:before {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.300049 6.7L1.70005 8.1L6.00005 3.8V16H8.00005V3.8L12.3 8.1L13.7 6.7L7.00005 0L0.300049 6.7Z' fill='%234FBF67'/%3E%3C/svg%3E%0A");
}

.status.negative {
  color: #ff7a68;
}

.status.negative:before {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.300049 9.3L1.70005 7.9L6.00005 12.2V0H8.00005V12.2L12.3 7.9L13.7 9.3L7.00005 16L0.300049 9.3Z' fill='%23FF7A68'/%3E%3C/svg%3E%0A");
}

.favorite {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.favorite:before,
.favorite:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% auto;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.favorite:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M8 .867l2.318 4.696 5.182.753-3.75 3.655.885 5.162L8 12.696l-4.635 2.437.885-5.162L.5 6.316l5.182-.753L8 .867z' stroke='%23808191' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.favorite:after {
  z-index: 2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M16.144 5.439l-4.317-.628L9.9.9a1.04 1.04 0 0 0-1.8 0L6.173 4.812l-4.317.627A1 1 0 0 0 1.3 7.145l3.123 3.045-.737 4.3a1 1 0 0 0 1.451 1.054L9 13.513l3.861 2.029a1 1 0 0 0 1.451-1.054l-.737-4.3L16.7 7.145a1 1 0 0 0-.554-1.705l-.002-.001z' fill='%23355dff'/%3E%3C/svg%3E");
  opacity: 0;
}

.favorite.active:before {
  opacity: 0;
}

.favorite.active:after {
  opacity: 1;
}

.switch {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch__in {
  display: inline-block;
}

.switch__box {
  position: relative;
  display: block;
  width: 72px;
  height: 32px;
  background: rgba(228, 228, 228, 0.4);
  border-radius: 16px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.switch__box:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 4px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ee7c36;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.switch__icon {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
}

.switch__icon .icon {
  font-size: 24px;
  fill: #808191;
  width: 20px;
}

.switch__icon .icon-theme-dark {
  display: none;
}

.chart_image {
  height: 200px;
}

.switch__input:checked + .switch__flex .switch__box:before {
  -webkit-transform: translate(24px, -50%);
  -ms-transform: translate(24px, -50%);
  transform: translate(24px, -50%);
}

.switch_theme .switch__in {
  position: relative;
  padding-left: 46px;
  -webkit-transition: padding 0.2s;
  -o-transition: padding 0.2s;
  transition: padding 0.2s;
}

.switch_theme .switch__input:checked + .switch__in .switch__box:before {
  width: 8px;
  height: 24px;
  border-radius: 4px;
  -webkit-transform: translate(48px, -50%);
  -ms-transform: translate(48px, -50%);
  transform: translate(48px, -50%);
}

.switch_theme .switch__input:checked + .switch__in .icon-theme-dark {
  display: inline-block;
}

.switch_theme .switch__input:checked + .switch__in .icon-theme-light {
  display: none;
}

.dark .switch__box {
  background: rgba(228, 228, 228, 0.1);
}

.field {
  position: relative;
}

.field__label {
  position: absolute;
  top: 32px;
  right: 24px;
  left: 24px;
  pointer-events: none;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: #808191;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.field__label .icon {
  position: relative;
  bottom: -3px;
  margin-left: 5px;
  font-size: 14px;
  color: #11142d;
}

.field__max {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: none;
  color: #ee7c36;
}

.field__input {
  width: 100%;
  height: 80px;
  padding: 18px 22px 0;
  border-radius: 12px;
  border: 2px solid transparent;
  background: rgba(228, 228, 228, 0.3);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.field__input:focus {
  border-color: #ee7c36;
  background: #ffffff;
}

.field.active .field__label {
  -webkit-transform: translateY(-12px);
  -ms-transform: translateY(-12px);
  transform: translateY(-12px);
}

.field.success:before {
  content: '';
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 23px;
  height: 18px;
  background: url("data:image/svg+xml,%3Csvg width='23' height='18' viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 18L0 10L3 7L8 12L20 0L23 3L8 18Z' fill='%234FBF67'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/100% auto;
}

.dark .field__label .icon {
  fill: #ffffff;
}

.dark .field__input {
  background: rgba(228, 228, 228, 0.03);
  color: #ffffff;
}

.checkbox {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.checkbox__in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox__tick {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #e1e1e1;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.checkbox__tick:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 9px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 5.375L1.5 3.375L0 4.875L3.5 8.375L10 1.875L8.5 0.375L3.5 5.375Z' fill='white'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/100% auto;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.checkbox__text {
  margin-left: 8px;
  padding-top: 2px;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  color: #808191;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.checkbox:hover .checkbox__tick {
  border-color: #ee7c36;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick {
  background: #ee7c36;
  border-color: #ee7c36;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick:before {
  opacity: 1;
}

.checkbox__input:checked + .checkbox__in .checkbox__text {
  color: #11142d;
}

.dark .checkbox__tick {
  border-color: #ffffff;
}

.dark .checkbox__input:checked + .checkbox__in .checkbox__text {
  color: #ffffff;
}
