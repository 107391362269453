.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 255px;
  background: #ffffff;
  border-right: 1px solid #e4e4e4;
  padding: 132px 20px 72px;
  -webkit-transition: width 0.2s;
  -o-transition: width 0.2s;
  transition: width 0.2s;
}

.sidebar__head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 132px;
  padding-top: 12px;
}

.sidebar__head .sidebar__close {
  display: none;
  margin-left: 8px;
  font-size: 0;
}

.sidebar__head .sidebar__close .icon {
  font-size: 18px;
  width: 15px;
  fill: #11142d;
}

.sidebar__head .sidebar__close i {
  font-size: 18px;
  color: #11142d;
}

.sidebar__logo {
  display: inline-block;
  margin-right: 28px;
  font-size: 0;
}

.sidebar__pic_dark {
  display: none;
}

.sidebar__toggle {
  font-size: 0;
}

.sidebar__toggle svg {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.sidebar__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.sidebar__body::-webkit-scrollbar {
  display: none;
}

.sidebar__nav {
  margin-bottom: 32px;
}

.sidebar__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  border-radius: 12px;
  white-space: nowrap;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.sidebar__item:hover,
.sidebar__item.active {
  color: #ee7c36;
}

.sidebar__item:hover .icon,
.sidebar__item.active .icon {
  fill: #ee7c36;
}

.sidebar__item.active {
  background: rgba(228, 228, 228, 0.3);
}

.sidebar__icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.sidebar__icon .icon {
  font-size: 15px;
  fill: #808191;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.sidebar__icon .icon-wallet,
.sidebar__icon .icon-document,
.sidebar__icon .icon-discount {
  font-size: 20px;
}

.sidebar__icon:before {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background: #ff6628;
  border-radius: 50%;
  opacity: 0;
}

.sidebar__icon.active:before {
  opacity: 1;
}

.sidebar__item .sidebar__text {
  margin-left: 16px;
}

.sidebar__mail {
  position: relative;
  margin-top: auto;
  padding: 24px;
  border-radius: 24px;
  background: -webkit-linear-gradient(
    226.42deg,
    #86e1a0 12.52%,
    #4fbf67 75.73%
  );
  background: -o-linear-gradient(226.42deg, #86e1a0 12.52%, #4fbf67 75.73%);
  background: linear-gradient(223.58deg, #86e1a0 12.52%, #4fbf67 75.73%);
  margin-bottom: 32px;
}

.sidebar__info {
  /* max-width: 128px; */
  max-width: 158px;
  font-family: 'Poppins', sans-serif;
  /* font-size: 24px; */
  font-size: 19px;
  line-height: 1.33333;
  font-weight: 500;
  color: #ffffff;
}

.sidebar__user img {
  border-radius: 50%;
}

.sidebar__mail .sidebar__close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.sidebar__mail .sidebar__close .icon {
  font-size: 9px;
  fill: #11142d;
  width: 10px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.sidebar__mail .sidebar__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar__btn {
  margin-top: 12px;
}

.sidebar__search {
  display: none;
  position: relative;
  margin-top: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.sidebar__input {
  width: 100%;
  height: 56px;
  padding: 0 56px 0 24px;
  background: rgba(228, 228, 228, 0.3);
  border: none;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
}

.sidebar__input::-webkit-input-placeholder {
  color: #808191;
}

.sidebar__input::-moz-placeholder {
  color: #808191;
}

.sidebar__input::-ms-input-placeholder {
  color: #808191;
}

.sidebar__input::placeholder {
  color: #808191;
}

.sidebar__start {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  font-size: 0;
}

.sidebar__start .icon {
  font-size: 20px;
  fill: #11142d;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.sidebar__start:hover .icon {
  fill: #ee7c36;
}

.sidebar__bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 72px;
  padding: 0 20px;
  -webkit-transition: padding 0.2s;
  -o-transition: padding 0.2s;
  transition: padding 0.2s;
}

.sidebar__user {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar__user img {
  min-width: 100%;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.sidebar.active,
.sidebar.small {
  width: 96px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.sidebar.small.active {
  width: 255px;
  -webkit-box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
}

.sidebar.active .sidebar__logo,
.sidebar.small .sidebar__logo {
  display: none;
}

.sidebar.small.active .sidebar__logo {
  display: inline-block;
}

.active .sidebar__toggle svg,
.sidebar.small .sidebar__toggle svg {
  -webkit-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.sidebar.small.active .sidebar__toggle svg {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.sidebar.active .sidebar__item,
.sidebar.small .sidebar__item {
  padding: 0 16px;
}

.sidebar.small.active .sidebar__item {
  padding: 0 20px;
}

.sidebar.active .sidebar__mail,
.sidebar.small .sidebar__mail {
  display: none;
}

.sidebar.small.active .sidebar__mail {
  display: block;
}

.sidebar.active .sidebar__bottom,
.sidebar.small .sidebar__bottom {
  padding: 0 12px;
}

.sidebar.small.active .sidebar__bottom {
  padding: 0 20px;
}

.sidebar.active .switch__in,
.sidebar.small .switch__in {
  padding: 0;
}

.sidebar.small.active .switch__in {
  padding-left: 46px;
}

.sidebar.active .switch__icon,
.sidebar.small .switch__icon {
  left: 46px;
}

.sidebar.active .switch__icon .icon,
.sidebar.small .switch__icon .icon {
  font-size: 16px;
}

.sidebar.active .switch__input:checked + .switch__in .switch__icon,
.sidebar.small .switch__input:checked + .switch__in .switch__icon {
  left: 10px;
}

.sidebar.small.active .switch__icon {
  left: 0;
}

.sidebar.small.active .switch__icon .icon {
  font-size: 24px;
}

.sidebar.small.active .switch__input:checked + .switch__in .switch__icon {
  left: 0;
}

.dark .sidebar {
  background: #1f2128;
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .sidebar__pic_light {
  display: none;
}

.dark .sidebar__pic_dark {
  display: inline-block;
}

.dark .sidebar__toggle svg > path {
  stroke: #ffffff;
}

.dark .sidebar__toggle svg g {
  stroke: rgba(128, 129, 145, 0.7);
}

.dark .sidebar__toggle svg g path:nth-child(2) {
  opacity: 0.6;
}

.dark .sidebar__item:hover {
  color: #ffffff;
}

.dark .sidebar__item:hover .icon {
  fill: #ffffff;
}

.dark .sidebar__item.active {
  background: #191b20;
  color: #ee7c36;
}

.dark .sidebar__item.active .icon {
  fill: #ee7c36;
}

.dark .sidebar__mail .sidebar__close {
  background: #242731;
}

.dark .sidebar__mail .sidebar__close .icon {
  fill: #ffffff;
}

.dark .sidebar__mail .sidebar__btn {
  background: #ffffff;
  color: #11142d;
}

.dark .sidebar__mail .sidebar__btn:hover {
  background: #e4e4e4;
}

.dark .sidebar__head .sidebar__close .icon {
  fill: #ffffff;
}

.dark .sidebar__head .sidebar__close i {
  color: #ffffff;
}

.dark .sidebar__input {
  background: rgba(25, 27, 32, 0.3);
  color: #ffffff;
}

.dark .sidebar__start .icon {
  fill: #ffffff;
}
