.page {
  overflow: hidden;
}

.page__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  padding-left: 255px;
  -webkit-transition: padding 0.2s;
  -o-transition: padding 0.2s;
  transition: padding 0.2s;
}

.page__container {
  padding: 0 64px 64px;
}

.page__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.page__col {
  padding: 0 64px 64px;
}

.page__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 426px);
  flex: 0 0 calc(100% - 426px);
  max-width: calc(100% - 426px);
}

.page__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 426px;
  flex: 0 0 426px;
  width: 426px;
  border-left: 1px solid #e4e4e4;
}

.page__col_pt100 {
  padding-top: 100px;
}

.page__col_pt198 {
  padding-top: 198px;
}

.page__head {
  margin-top: -80px;
  margin-bottom: 32px;
}

.page__col > .page__title {
  margin-bottom: 32px;
}

.page.wide .page__content,
.page.full .page__content {
  padding-left: 96px;
}

.page.full.wide .page__content {
  padding-left: 96px;
}

.dark .page__col:nth-child(2) {
  border-color: rgba(228, 228, 228, 0.1);
}

.top_container {
  background-color: #faeee5;
  text-align: center;
  color: #ee7c36;
  font-family: 'Poppins';
  font-size: 12px;
  padding: 3px;
  z-index: 100;
  position: fixed;
  width: 100%;
}

.dark .top_container {
  background-color: #323232;
}

.top_notification {
  margin-left: 176px;
}

.top_container span {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
