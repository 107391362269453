.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -8px 0 0 -8px;
}

.pagination__item,
.pagination__next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 36px;
  margin: 8px 0 0 8px;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  font-size: 12px;
  font-weight: 700;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.pagination__item:hover,
.pagination__next:hover {
  border-color: #ee7c36;
}

.pagination__item {
  width: 36px;
}

.pagination__next {
  width: 105px;
}

.pagination__next .icon {
  margin-left: 15px;
  width: 15px;
}

.pagination__item.active {
  background: #ee7c36;
  border-color: #ee7c36;
  color: #ffffff;
}

.dark .pagination__item,
.dark .pagination__next {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

.dark .pagination__item .icon,
.dark .pagination__next .icon {
  fill: #ffffff;
}

.dark .pagination__item:hover,
.dark .pagination__next:hover {
  border-color: #ee7c36;
}

.pagination {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  transition: all 0.8s;
}

.page-item {
  padding: 4px 10px;
  cursor: pointer;
  border: 1px solid #808191;
  border-radius: 12px;
  margin-right: 12px;
}

.page-item:hover {
  border: 1px solid #ee7c36;
  color: #ee7c36;
}

.page-item.active {
  border: 1px solid #ee7c36;
  color: #ee7c36;
}
