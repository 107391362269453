.search__container {
  position: relative;
  padding: 48px;
  border-radius: 24px;
  background: -webkit-radial-gradient(
    0% 0%,
    103.03% 103.03%,
    #d080ff 0%,
    #6c5dd3 100%
  );
  background: -o-radial-gradient(
    0% 0%,
    103.03% 103.03%,
    #d080ff 0%,
    #6c5dd3 100%
  );
  background: radial-gradient(
    103.03% 103.03% at 0% 0%,
    #d080ff 0%,
    #6c5dd3 100%
  );
  color: #ffffff;
}

.search__wrap {
  position: relative;
  z-index: 3;
  max-width: 380px;
  margin-bottom: 36px;
}

.search__title {
  margin-bottom: 16px;
}

.search__form {
  position: relative;
  z-index: 3;
  max-width: 496px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

.search__input {
  width: 100%;
  height: 64px;
  padding: 0 64px 0 30px;
  border-radius: 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
  color: #11142d;
}

.search__input::-webkit-input-placeholder {
  color: #808191;
}

.search__input::-moz-placeholder {
  color: #808191;
}

.search__input::-ms-input-placeholder {
  color: #808191;
}

.search__input::placeholder {
  color: #808191;
}

.search__btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 64px;
  font-size: 0;
}

.search__btn .icon {
  font-size: 20px;
  fill: #11142d;
  width: 19px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.search__btn:hover .icon {
  fill: #ee7c36;
}

.search__preview {
  position: absolute;
  top: -27px;
  left: 50%;
  width: 740px;
}

.search__preview img {
  width: 100%;
}

.search:not(:last-child) {
  margin-bottom: 136px;
}

.categories__title {
  margin-bottom: 48px;
}

.categories__container {
  margin: 0 -12px;
}

.categories__item {
  display: block;
  height: 100%;
  margin: 0 12px;
  padding: 36px 32px 32px;
  border-radius: 20px;
  background: #ffffff;
  -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.07);
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.categories__item:hover {
  -webkit-box-shadow: 0 0 0 1px #ee7c36;
  box-shadow: 0 0 0 1px #ee7c36;
  color: #ee7c36;
}
.prices__text a {
  text-decoration: none;
  color: inherit;
}

.prices__text a:hover {
  text-decoration: underline;
}

.categories__item.active {
  -webkit-box-shadow: 0 0 0 1px #ee7c36;
  box-shadow: 0 0 0 1px #ee7c36;
  color: #ee7c36;
}

.categories__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
  border-radius: 50%;
}

.categories__preview img {
  max-height: 22px;
}

.categories .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.categories .owl-item {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.categories .owl-item.active {
  visibility: visible;
  opacity: 1;
}

.categories .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.categories .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 46px;
}

.categories .owl-carousel button.owl-dot {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: #e4e4e4;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.categories .owl-carousel button.owl-dot.active {
  background: #ee7c36;
}

.categories .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 4px;
}

.categories:not(:last-child) {
  margin-bottom: 80px;
}

.dark .categories__item {
  background: #242731;
  color: #ffffff;
}

.dark .categories .owl-carousel button.owl-dot {
  background: rgba(255, 255, 255, 0.5);
}

.dark .categories .owl-carousel button.owl-dot.active {
  background: #ee7c36;
}

.prices__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 48px;
}

.prices__table {
  display: table;
  width: 100%;
}

.prices__row {
  display: table-row;
  border-radius: 12px;
  cursor: pointer;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.prices__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.2);
}

.prices__row:not(.prices__row_head):hover {
  background: none;
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
}

.prices__cell {
  display: table-cell;
  vertical-align: middle;
  height: 72px;
  padding-left: 16px;
  font-weight: 600;
}

.prices__row a {
  text-decoration: none;
  color: inherit;
}

.prices__row a:hover {
  text-decoration: underline;
}

.prices__cell:first-child {
  border-radius: 12px 0 0 12px;
  font-size: 0;
}

.prices__cell:nth-child(3) {
  font-weight: 600;
  color: #808191;
}

.prices__cell:last-child {
  padding-right: 16px;
  border-radius: 0 12px 12px 0;
}

.prices__row_head .prices__cell {
  height: 40px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #808191;
}

.prices__row_head .prices__cell:first-child {
  border-radius: 8px 0 0 8px;
}

.prices__row_head .prices__cell:last-child {
  border-radius: 0 8px 8px 0;
}

.prices__company {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.prices__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.prices__logo img {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.prices__chart {
  position: relative;
  bottom: -4px;
  width: 112px;
  height: 30px;
}

.prices__chart .apexcharts-canvas svg {
  overflow: visible;
}

.prices__btns {
  margin-top: 40px;
  text-align: center;
}

.dark .prices__row {
  color: #ffffff;
}

.dark .prices__row:nth-child(2n + 1) {
  background: rgba(228, 228, 228, 0.03);
}

.dark .prices__row:not(.prices__row_head):hover {
  background: #242731;
}
