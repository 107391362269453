@media only screen and (min-width: 768px) {
  .exchange__top {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .exchange .orders:not(:last-child) {
    display: block !important;
  }
  .exchange .actions:not(:last-child) {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .market__tabs {
    display: block !important;
  }
  .market__transactions {
    display: block !important;
  }
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media only screen and (max-width: 1799px) {
  .slider_home .slider__preview {
    left: 20%;
  }
}

@media only screen and (max-width: 1579px) {
  .slider_home .slider__preview {
    left: 20px;
  }
  .notifications__text {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1419px) {
  .page__row {
    padding: 0 64px 64px;
  }
  .page__col {
    padding: 0;
  }
  .page__col:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 290px);
    flex: 0 0 calc(100% - 290px);
    max-width: calc(100% - 290px);
    padding-right: 64px;
  }
  .page__col:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 290px;
    flex: 0 0 290px;
    width: 290px;
    border: none;
  }
  .header_border:before {
    display: none;
  }
  .widgets__variants .widgets__link {
    min-width: auto;
    padding: 0 8px;
  }
  .activity__table.small .activity__hidden {
    display: none;
  }
  .activity__table.small .activity__row_head .activity__hidden {
    display: none;
  }
  .activity__cell {
    padding-right: 25px;
  }
  .activity__table.small .activity__cell:nth-child(3) {
    display: table-cell;
  }
  .activity__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0;
    overflow: auto;
    padding: 20px;
    background: rgba(27, 29, 33, 0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .activity__sidebar.visible {
    visibility: visible;
    opacity: 1;
  }
  .activity__wrap {
    width: 100%;
    max-width: 630px;
    margin: auto;
    padding: 32px;
    border-radius: 24px;
    background: #ffffff;
  }
  .dark .activity__wrap {
    background: #242731;
  }
  .activity__head {
    padding-right: 60px;
  }
  .activity__close {
    top: 26px;
    right: 24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  .promotions__cell:first-child {
    padding: 0 16px;
  }
  .promotions__cell:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    width: 70px;
  }
  .promotions__cell:nth-child(3) {
    padding: 0 16px 0 10px;
  }
  .wallets__sidebar {
    width: 353px;
  }
  .wallets__wrapper.small .wallets__group {
    width: 200px;
  }
  .wallets__flex .wallets__number {
    font-size: 32px;
  }
  .search__preview {
    left: 45%;
  }
  .categories__item {
    padding: 32px 24px;
  }
  .exchange__cell {
    padding: 24px 16px;
  }
  .exchange__container {
    padding-right: 48px;
  }
}

@media only screen and (max-width: 1339px) {
  .page__container {
    padding: 0 48px 64px;
  }
  .page__row {
    padding: 0 48px 64px;
  }
  .page__col:first-child {
    padding-right: 48px;
  }
  .header {
    padding: 48px 48px 36px;
  }
  .slider_promotions .slider__item {
    min-height: 420px;
  }
  .slider_promotions .slider__preview {
    bottom: -30%;
  }
  .activity__container {
    padding: 0 48px 64px;
  }
  .dark .activity__wrap {
    background: #242731;
  }
  .promotions__wrapper {
    padding: 0 48px 64px;
  }
  .promotions__title {
    margin-bottom: 40px;
  }
  .promotions__row {
    display: block;
  }
  .promotions__col:first-child {
    max-width: 100%;
    margin-bottom: 32px;
    padding: 0;
  }
  .promotions__col:nth-child(2) {
    width: auto;
  }
  .promotions__group_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    margin: 0 -48px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .promotions__group_row::-webkit-scrollbar {
    display: none;
  }
  .promotions__group_row:before,
  .promotions__group_row:after {
    content: '';
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 48px;
    height: 1px;
  }
  .promotions__group_row .promotions__box {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 360px;
  }
  .promotions__group_row .promotions__box:not(:last-child) {
    margin: 0 32px 0 0;
  }
  .wallets__container {
    padding: 0 48px 64px;
  }
  .wallets__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0;
    overflow: auto;
    padding: 20px;
    background: rgba(27, 29, 33, 0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .wallets__sidebar.visible {
    visibility: visible;
    opacity: 1;
  }
  .wallets__group {
    position: static;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: calc(50% - 12px);
  }
  .wallets__wrapper.small .wallets__group {
    width: calc(50% - 12px);
  }
  .wallets__wrapper .wallets__box:not(:last-child) {
    margin-bottom: 16px;
  }
  .wallets__btns {
    /* display: none; */
    position: relative;
    margin-top: 75px;
    margin-left: 30px;
  }
  .wallets__inner {
    max-width: 100%;
  }
  .wallets__wrap {
    width: 100%;
    max-width: 630px;
    margin: auto;
    padding: 32px;
    border-radius: 24px;
    background: #ffffff;
  }
  .wallets__head {
    padding-right: 50px;
  }
  .wallets__close {
    top: 26px;
    right: 24px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  .wallets__parameters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 32px 0;
    border-width: 1px 0;
    border-style: solid;
    border-color: #e4e4e4;
  }
  .wallets__parameter {
    padding: 0;
    border: none;
  }
  .wallets__parameter:last-child {
    margin-left: auto;
  }
  .wallets .card {
    margin-top: 32px;
  }
  .dark .wallets__wrap {
    background: #242731;
  }
  .dark .wallets__parameters {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .balances__table.small .balances__cell:nth-child(4),
  .balances__table.small .balances__cell:nth-child(5) {
    display: table-cell;
  }
  .dark .operations__item {
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  .search__form {
    max-width: 450px;
  }
  .prices__cell:nth-child(5) {
    display: none;
  }
  .details__company {
    width: 230px;
  }
  .details__head .details__info {
    font-size: 21px;
  }
  .details__money {
    font-size: 40px;
  }
  .details__box {
    padding: 32px;
  }
  .details__price {
    font-size: 16px;
  }
  .exchange__cell {
    padding: 24px;
  }
  .exchange__cell:nth-child(4) {
    border: none;
  }
  .exchange__cell:nth-child(n + 5) {
    display: none;
  }
}

@media only screen and (max-width: 1259px) {
  .page__container {
    padding: 0 64px 64px;
  }
  .page__row {
    display: block;
    padding: 0 64px 64px;
  }
  .page__col:first-child {
    max-width: 100%;
    margin-bottom: 64px;
    padding: 0;
  }
  .page__col:nth-child(2) {
    width: 100%;
  }
  .sidebar {
    padding-top: 120px;
  }
  .sidebar__head {
    height: 120px;
    padding: 0;
  }
  .sidebar.small.active .sidebar__mail {
    display: none;
  }
  .header {
    padding: 36px 64px;
  }
  .slider:not(:last-child) {
    margin-bottom: 48px;
  }
  .slider_home .slider__preview.lottie__money {
    left: revert !important;
    right: -80px !important;
    bottom: -50px;
    width: 740px;
  }
  .slider_home .slider__preview.lottie_money_2 {
    left: 207px !important;
    bottom: 60px;
    width: 740px;
  }
  .widgets__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -32px -24px 0;
  }
  .widgets__wrap .widgets__box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 48px);
    flex: 0 0 calc(50% - 48px);
    width: calc(50% - 48px);
    margin: 32px 24px 0;
  }
  .widgets__wrap .widgets__box:not(:last-child) {
    margin-bottom: 0;
  }
  .card__chart_total-balance {
    margin-top: 0;
  }
  .card__inner {
    text-align: left;
  }
  .card__inner .card__price {
    margin-bottom: 24px;
  }
  .card_widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .card_widget .card__chart {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 240px;
    margin-right: 24px;
  }
  .card:not(:last-child) {
    margin-bottom: 64px;
  }
  .login__col {
    padding: 80px 48px;
  }
  .login__col:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 40%;
  }
  .login__col:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    width: 60%;
  }
  .login__preview {
    /* left: -130%; */
  }
  .activity__container {
    padding: 0 64px 64px;
  }
  .promotions__wrapper {
    padding: 0 64px 64px;
  }
  .promotions__group_row {
    margin: 0 -64px;
  }
  .promotions__group_row:before,
  .promotions__group_row:after {
    width: 64px;
  }
  .promotions__group_row .promotions__box {
    width: 330px;
  }
  .promotions__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
  }
  .promotions__transactions,
  .promotions__wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
  }
  .promotions_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -16px;
  }
  .wallets__container {
    padding: 0 64px 64px;
  }
  .search__container {
    padding: 32px;
  }
  .search__form {
    max-width: 360px;
  }
  .search__preview {
    width: 600px;
  }
  .details__company {
    width: 250px;
  }
  .exchange__top {
    margin-bottom: 32px;
  }
  .exchange__row {
    display: block;
  }
  .exchange__container {
    margin-bottom: 48px;
    padding: 0;
  }
  .exchange__sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    width: auto;
    margin: 0 -16px;
  }
  .exchange .orders:not(:last-child) {
    margin-bottom: 0;
  }
  .exchange .actions:not(:last-child) {
    margin-bottom: 0;
  }
  .exchange .orders,
  .exchange .actions {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px;
  }

  .actions__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .actions__btn {
    margin-top: auto;
  }
  .desktop-hide {
    display: none !important;
  }
  .desktop-show {
    display: block !important;
  }
  .desktop-text-right {
    text-align: right !important;
  }
  .exchange__banner {
    height: 300px;
  }
}

@media (hover: hover) {
  .switch:hover {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 1179px) {
  .page__content {
    padding-left: 96px;
  }
  .sidebar {
    width: 96px;
  }
  .sidebar__logo {
    display: none;
  }
  .sidebar__toggle svg {
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
  .sidebar__item {
    padding: 0 16px;
  }
  .sidebar__mail {
    display: none;
  }
  .sidebar__bottom {
    padding: 0 12px;
  }
  .sidebar .switch__in {
    padding: 0;
  }
  .sidebar .switch__icon {
    left: 46px;
  }
  .sidebar .switch__icon .icon {
    font-size: 16px;
  }
  .sidebar .switch__input:checked + .switch__in .switch__icon {
    left: 10px;
  }
  .sidebar.active {
    width: 255px;
    -webkit-box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 0 40px rgba(0, 0, 0, 0.1);
  }
  .sidebar.active .sidebar__logo {
    display: inline-block;
  }
  .active .sidebar__toggle svg {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .sidebar.active .sidebar__item {
    padding: 0 20px;
  }
  .sidebar.active .sidebar__bottom {
    padding: 0 20px;
  }
  .sidebar.active .switch__in {
    padding-left: 46px;
  }
  .sidebar.active .switch__icon {
    left: 0;
  }
  .sidebar.active .switch__icon .icon {
    font-size: 24px;
  }
  .sidebar.active .switch__input:checked + .switch__in .switch__icon {
    left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .page__content {
    padding-left: 0;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    -o-transition: transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .page__head {
    margin-top: 0;
  }
  .page.wide .page__content,
  .page.full .page__content {
    padding: 0;
  }
  .page.full.wide .page__content {
    padding: 0;
  }
  .page.toggle .page__content {
    -webkit-transform: translateX(255px);
    -ms-transform: translateX(255px);
    transform: translateX(255px);
  }
  .sidebar {
    width: 255px;
    padding-bottom: 90px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .sidebar__head .sidebar__close {
    display: inline-block;
  }
  .sidebar__logo {
    display: inline-block;
  }
  .sidebar__toggle {
    display: none;
  }
  .sidebar__item {
    padding: 0 20px;
  }
  .sidebar__mail {
    display: none;
  }
  .sidebar__search {
    display: block;
  }
  .sidebar__start {
    width: 56px;
  }
  .sidebar__bottom {
    padding: 0 20px;
  }
  .sidebar .switch__in {
    padding-left: 46px;
  }
  .sidebar .switch__icon {
    left: 0;
  }
  .sidebar .switch__icon .icon {
    font-size: 24px;
  }
  .sidebar .switch__input:checked + .switch__in .switch__icon {
    left: 0;
  }
  .sidebar.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar.small.active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .header {
    padding: 32px 64px;
  }
  .header__search {
    display: none;
  }
  .kyc_notification {
    margin-left: 12px;
    max-width: 220px;
  }
  .header__logo {
    display: inline-block;
  }
  .header__indicator {
    display: none;
  }
  .header__toggle {
    display: inline-block;
  }
  .widgets__wrap {
    margin: -24px -12px 0;
  }
  .widgets__wrap .widgets__box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 24px);
    flex: 0 0 calc(50% - 24px);
    width: calc(50% - 24px);
    margin: 24px 12px 0;
  }
  .login__row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px;
  }
  .login__col {
    padding: 0;
  }
  .login__col:first-child {
    display: none;
  }
  .login__col:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 500px;
    flex: 0 0 500px;
    width: 500px;
  }
  .login__logo img {
    width: 60px;
  }
  .login__col:nth-child(2) .login__logo {
    display: inline-block;
  }
  .login__preview {
    display: none;
  }
  .login__form {
    max-width: 100%;
  }
  .notifications__item {
    position: relative;
    border-radius: 0;
    border-bottom: 1px solid #e4e4e4;
  }
  .notifications__item:hover {
    background: none;
  }
  .notifications__head {
    margin-bottom: 24px;
  }
  .notifications__body {
    position: static;
  }
  .notifications .dropdown {
    top: 113px;
    right: auto;
    left: 20px;
    visibility: visible;
    opacity: 1;
  }
  .notifications .dropdown__body {
    left: -16px;
    right: auto;
  }
  .dark .notifications__item {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .dark .notifications__item:hover {
    background: none;
  }
  .activity__sorting {
    margin-bottom: 32px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .activity__select {
    margin: 0;
  }
  .activity__select .select {
    padding-left: 20px;
  }
  .activity__select .activity__icon {
    display: none;
  }
  .activity__row:not(.activity__row_head):hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .activity__table.small .activity__hidden {
    display: block;
  }
  .activity__table .activity__hidden {
    display: block;
  }
  .activity__table.small .activity__row_head .activity__hidden {
    display: inline;
  }
  .activity__table .activity__row_head .activity__hidden {
    display: inline;
  }
  .activity__cell {
    padding-right: 16px;
  }
  .activity__cell:first-child {
    padding-left: 16px;
  }
  .activity__cell:nth-child(3) {
    display: none;
  }
  .activity__table.small .activity__cell:nth-child(3) {
    display: none;
  }
  .popup_settings .mfp-close {
    right: 24px;
  }
  .balances__cell:nth-child(3),
  .balances__cell:nth-child(4) {
    display: none;
  }
  .balances__table.small .balances__cell:nth-child(4) {
    display: none;
  }
  .search__preview {
    left: 52%;
    width: 550px;
  }
  .search:not(:last-child) {
    margin-bottom: 80px;
  }
  .prices__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -64px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .prices__container::-webkit-scrollbar {
    display: none;
  }
  .prices__container:before,
  .prices__container:after {
    content: '';
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 64px;
    height: 1px;
  }
  .prices__table {
    width: 860px;
  }
  .prices__row:not(.prices__row_head):hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .exchange__cell:nth-child(4) {
    border: none;
  }
  .exchange__cell:nth-child(n + 4) {
    display: none;
  }
  .exchange__sidebar {
    display: block;
    margin: 0;
  }
  .exchange .orders:not(:last-child) {
    margin-bottom: 56px;
  }
  .exchange .orders,
  .exchange .actions {
    width: 100%;
    margin: 0;
  }
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-text-right {
    text-align: right !important;
  }
  .exchange__banner {
    display: none;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -ms-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 900px) {
  .mfp-container {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .select {
    height: 56px;
    padding-left: 16px;
    padding-right: 36px;
    background: rgba(228, 228, 228, 0.3);
    border-radius: 12px;
    line-height: 56px;
  }
  .select:before {
    right: 16px;
  }
  .select .current {
    display: block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select .list {
    padding: 20px 16px;
  }
  .select .option {
    padding-left: 0;
  }
  .select .option:before {
    display: none;
  }
  .select_empty .list {
    left: 0;
    right: 0;
    width: auto;
    margin-top: 7px;
  }
  .select_empty.open {
    -webkit-box-shadow: inset 0 0 0 2px #ee7c36;
    box-shadow: inset 0 0 0 2px #ee7c36;
    background: #ffffff;
  }
  .select_arrows {
    padding-left: 20px;
  }
  .dark .select {
    background: rgba(228, 228, 228, 0.03);
  }
  .more {
    margin-top: 24px;
  }
  .page__container {
    padding: 0 16px 32px;
  }
  .page__container_exchange {
    padding-bottom: 0;
  }
  .page__row {
    padding: 0 16px 32px;
  }
  .page__col:first-child {
    margin-bottom: 42px;
  }
  .page__head {
    margin-bottom: 24px;
  }
  .page__category {
    font-size: 18px;
  }
  .page__title {
    font-size: 32px;
  }
  .sidebar {
    padding: 94px 20px 72px;
  }
  .sidebar__head {
    height: 94px;
  }
  .sidebar__nav {
    margin-bottom: 16px;
  }
  .sidebar__item {
    height: 52px;
  }
  .sidebar__user {
    display: inline-block;
    width: 50px;
  }
  .header {
    padding: 24px 16px;
  }
  .header__logo img {
    width: 46px;
  }
  .header__item_notifications {
    position: static;
    margin-right: 24px;
  }
  .header__item_download {
    display: none;
  }
  .header__item_lang {
    display: none;
  }
  .header__item_notifications .header__body {
    top: 100%;
    left: 0;
    right: 0;
    width: auto;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    padding: 0 16px 24px;
    -webkit-box-shadow: 0 10px 30px rgba(17, 20, 45, 0.2);
    box-shadow: 0 10px 30px rgba(17, 20, 45, 0.2);
  }
  .header__notifications {
    margin-bottom: 12px;
  }
  .header__notification {
    padding: 12px 0;
  }
  .header__icon {
    margin-right: 16px;
  }
  .header__info {
    font-size: 14px;
  }
  .header__user {
    display: none;
  }
  .header__toggle {
    margin: 0;
  }
  .slider_home .slider__item {
    min-height: 191px;
  }
  .slider_home .slider__preview {
    left: -80%;
    bottom: -145px;
  }
  .slider_promotions .slider__item {
    min-height: 508px;
  }
  .slider_promotions .slider__preview {
    left: 15%;
    bottom: -27%;
    width: 600px;
  }
  .slider:not(:last-child) {
    margin-bottom: 32px;
  }
  .widgets__sorting {
    margin: 0 -8px 24px;
    flex-direction: column;
    /* align-items: center; */
  }
  .widget_search__container {
    min-width: 100%;
    padding: 17px 5px 5px 5px;
  }
  .widget_searchicon__container {
    top: 27px;
    right: 10px;
  }
  .widget_search {
    width: 96%;
  }
  .widget_filter__input {
    padding: 25px !important;
    border-radius: 5px;
    margin-bottom: 0px;
  }
  .widgets__sorting .select {
    margin: 0;
  }
  .widgets__select {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    /* flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px); */
    flex: 0 0 calc(100% - 16px);
    width: calc(100% - 16px);
    margin: 0 8px;
  }
  .widgets__variants {
    display: none;
  }
  .widgets__list {
    display: block;
    margin: 0;
  }
  .widgets__item {
    max-width: 100%;
    margin: 0;
  }
  .widgets__item:not(:last-child) {
    margin-bottom: 32px;
  }
  .widgets__wrap {
    display: block;
    margin: 0;
  }
  .widgets__wrap .widgets__box {
    width: 100%;
    margin: 0;
  }
  .widgets__wrap .widgets__box:not(:last-child) {
    margin-bottom: 24px;
  }
  .card__chart_total-balance {
    margin-top: -15px;
  }
  .card__inner {
    text-align: center;
  }
  .card_widget {
    display: block;
  }
  .card_widget .card__chart {
    width: 100%;
    margin-right: 0;
  }
  .dropdown__body {
    top: calc(100% + 5px);
    right: 0;
  }
  .login__row {
    display: block;
  }
  .login__col:nth-child(2) {
    width: 100%;
  }
  .login__logo img {
    width: 40px;
  }
  .login__verify {
    margin-bottom: 24px;
  }
  .login__note {
    margin-bottom: 24px;
  }
  .notifications__sorting {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -8px 24px;
  }
  .notifications__select {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px;
  }
  .notifications__search {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 16px);
    flex: 0 0 calc(100% - 16px);
    margin: 16px 8px 0;
  }
  .notifications__input {
    padding-left: 24px;
  }
  .notifications__container {
    padding: 0 8px 32px;
  }
  .notifications__head {
    margin-bottom: 16px;
  }
  .notifications__time {
    margin-left: 15px;
  }
  .notifications__body {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .notifications__status {
    position: relative;
    top: 3px;
    margin-left: 15px;
  }
  .notifications .dropdown {
    top: 118px;
  }
  .activity__container {
    padding: 0 16px 32px;
  }
  .activity__sorting {
    margin: 0 -8px 24px;
  }
  .activity__select {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    width: calc(50% - 16px);
    margin: 0 8px;
  }
  .activity__stage {
    margin-bottom: 24px;
  }
  .activity__cell:first-child {
    display: none;
  }
  .activity__cell:nth-child(2) {
    border-radius: 12px 0 0 12px;
    padding-left: 16px;
  }
  .activity__cell:nth-child(4) {
    border-radius: 0 12px 12px 0;
  }
  .activity__cell:nth-child(n + 5) {
    display: none;
  }
  .activity__logo {
    margin-right: 10px;
  }
  .activity__price,
  .activity__address {
    font-size: 13px;
  }
  .activity__sidebar {
    padding: 0;
  }
  .activity__wrap {
    min-height: 100vh;
    padding: 24px 16px;
    border-radius: 0;
  }
  .activity__close {
    top: 22px;
    right: 16px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .promotions__wrapper {
    padding: 0 16px 32px;
  }
  .promotions__brand {
    font-size: 18px;
  }
  .promotions__title {
    margin-bottom: 24px;
    font-size: 32px;
  }
  .promotions__row {
    margin-bottom: 32px;
  }
  .promotions__stage {
    font-size: 18px;
  }
  .promotions__group_row {
    display: block;
    margin: 0;
  }
  .promotions__group_row:before,
  .promotions__group_row:after {
    display: none;
  }
  .promotions__box {
    padding: 24px;
  }
  .promotions__group_row .promotions__box {
    width: 100%;
  }
  .promotions__group_row .promotions__box:not(:last-child) {
    margin: 0 0 16px;
  }
  .promotions__list {
    display: block;
    margin: 0 0 32px;
  }
  .promotions__item {
    width: 100%;
    margin: 0;
  }
  .promotions__item:not(:last-child) {
    margin-bottom: 32px;
  }
  .promotions__transactions,
  .promotions__wrap {
    width: 100%;
    margin: 0;
  }
  .promotions__transactions:not(:last-child),
  .promotions__wrap:not(:last-child) {
    margin-bottom: 32px;
  }
  .promotions__cell:first-child {
    padding: 0 24px;
  }
  .promotions__cell:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    width: 90px;
  }
  .promotions__cell:nth-child(3) {
    padding: 0 24px;
  }
  .promotions_flex {
    display: block;
    margin: 0;
  }
  .promotion__body {
    padding: 24px 8px 16px;
  }
  .promotion__text:not(:last-child) {
    margin-bottom: 24px;
  }
  .promotion__note {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .promotion__icon {
    margin-top: 5px;
  }
  .promotion__btn {
    min-width: 100%;
  }
  .popup > .popup__title {
    margin-bottom: 32px;
  }
  .popup__nav {
    flex-wrap: wrap;
  }
  .popup__nav i {
    display: inline;
    color: gray;
  }
  .popup__nav span {
    display: none;
  }
  .popup__user:not(:last-child) {
    padding-bottom: 24px;
  }
  .popup__ava {
    width: 70px;
    height: 70px;
    margin-right: 16px;
  }
  .popup__btns {
    margin-bottom: 5px;
  }
  .popup__btns .popup__btn {
    padding: 0 10px;
  }
  .popup__upload {
    min-width: auto;
    padding: 0 10px;
  }
  .popup__default {
    font-size: 12px;
  }
  .popup__row {
    display: block;
    margin: 0;
  }
  .popup__row .popup__field {
    width: 100%;
    margin: 0;
  }
  .popup__row .popup__field:not(:last-child) {
    margin-bottom: 20px;
  }
  .popup__item > .popup__btn {
    min-width: 100%;
  }
  .popup__form {
    margin: 20px 0 0;
  }
  .popup__title {
    margin-bottom: 24px;
  }
  .popup__box:not(:last-child) {
    margin-bottom: 32px;
  }
  .popup__scan {
    display: grid;
    place-items: center;
  }
  .popup__scan .popup__preview {
    width: fit-content;
    /* margin: 0 auto 16px; */
  }
  .popup__scan .popup__details {
    padding: 0;
    text-align: center;
  }
  .popup__numbers {
    margin: 0 -5px;
  }
  .popup__number {
    margin: 0 5px;
  }
  .popup__number input {
    height: 44px;
    font-size: 20px;
    font-weight: 500;
  }
  .popup__table tr th:nth-child(3),
  .popup__table tr td:nth-child(3) {
    border-radius: 0 8px 8px 0;
  }
  .popup__table tr th:nth-child(n + 4),
  .popup__table tr td:nth-child(n + 4) {
    display: none;
  }
  .popup .mfp-close {
    width: 40px;
    height: 40px;
  }
  .popup_settings {
    min-height: 100vh;
    margin: 0;
    padding: 32px 24px;
    border-radius: 0;
  }
  .mfp-container {
    padding: 0;
  }
  .wallets__container {
    padding: 0 16px 32px;
  }
  .wallets__sidebar {
    padding: 0;
    border-radius: 0;
  }
  .wallets__wrapper {
    margin-bottom: 40px;
    padding: 24px;
  }
  .wallets__row {
    display: block;
  }
  .wallets__total {
    margin-bottom: 48px;
    padding: 0;
  }
  .wallets__group {
    width: 100%;
  }
  .wallets__inner {
    margin-top: 32px;
  }
  .wallets__list {
    display: block;
    margin: 0;
  }
  .wallets__item {
    width: 100%;
    margin: 0;
  }
  .wallets__item:not(:last-child) {
    margin-bottom: 24px;
  }
  .wallets__wrap {
    padding: 24px 16px;
    border-radius: 0;
  }
  .wallets__close {
    top: 22px;
    right: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .wallets__parameters {
    display: block;
    padding: 0;
    border-width: 1px 0 0;
  }
  .wallets__parameter {
    padding: 24px 0;
    border-bottom: 1px solid #e4e4e4;
  }
  .wallets__parameter:last-child {
    padding-bottom: 24px;
  }

  .balances__title {
    margin-bottom: 24px;
  }
  .balances__cell {
    padding-left: 16px;
  }
  .balances__cell:nth-child(n + 3) {
    display: none;
  }
  .balances__cell:last-child {
    display: table-cell;
    padding-right: 16px;
  }
  .balances__table.small .balances__cell:nth-child(4),
  .balances__table.small .balances__cell:nth-child(5) {
    display: none;
  }
  .balances__btns {
    margin-top: 24px;
  }
  .search__container {
    height: 500px;
    overflow: hidden;
  }
  .search__form {
    max-width: 100%;
  }
  .search__input {
    padding-left: 20px;
  }
  .search__preview {
    top: auto;
    left: -18%;
    bottom: -22%;
    width: 600px;
  }
  .search:not(:last-child) {
    margin-bottom: 32px;
  }
  .categories__select .select {
    height: 64px;
    padding-left: 24px;
    padding-right: 64px;
    border-radius: 16px;
    line-height: 64px;
    font-size: 18px;
  }
  .categories__select .select:before {
    right: 24px;
  }
  .categories__container {
    display: none;
  }
  .categories:not(:last-child) {
    margin-bottom: 42px;
  }
  .prices__head {
    margin-bottom: 24px;
  }

  .prices__head .select {
    min-width: 164px;
    margin-left: 20px;
  }
  .prices__title {
    font-size: 18px;
    font-weight: 500;
  }
  .prices__container {
    margin: 0 -16px;
  }
  .prices__container:before,
  .prices__container:after {
    width: 16px;
  }
  .prices__btns {
    margin-top: 16px;
  }
  .details__container {
    padding: 24px;
  }
  .details__top {
    display: block;
  }
  .details__company {
    width: 100%;
    margin: 0 0 32px;
  }
  .details__btns {
    margin: 0 -8px;
  }
  .details__btns .details__btn {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    min-width: calc(50% - 16px);
    margin: 0 8px;
    padding: 0 10px;
  }

  .details__btns .details__btn:not(:last-child) {
    margin-right: 8px;
  }
  .details__flex {
    display: block;
  }
  .details__status {
    margin: 12px 0 0;
  }
  .details__chart {
    margin: -32px -44px 5px -28px;
  }
  .details__chart .apexcharts-text {
    font-size: 10px;
  }
  .details__list {
    display: block;
  }
  .details__box {
    width: 100%;
    height: 112px;
    padding: 16px 32px;
  }
  .details__box:nth-child(2n + 1) {
    border-right: none;
  }
  .details__price {
    font-size: 18px;
  }
  .details__row {
    display: block;
  }
  .details__col:first-child {
    max-width: 100%;
    margin: 0 0 32px;
  }
  .exchange {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: calc(100vh - 94px);
  }
  .exchange__top {
    margin-bottom: 24px;
  }
  .exchange__cell:nth-child(n + 3) {
    display: none;
  }
  .exchange__container {
    margin: 0;
  }
  .exchange .orders:not(:last-child) {
    margin: 0;
  }
  .exchange .orders,
  .exchange .actions {
    display: none;
  }
  .exchange__foot {
    display: block;
  }
  .market {
    border: none;
    border-radius: 0;
  }
  .market__tabs {
    border: 1px solid #e4e4e4;
    /* background: #242731; */
    border-radius: 32px;
  }
  .market__head {
    display: block;
    padding-bottom: 16px;
  }
  .market__menu {
    margin: 0 -5px 16px;
  }
  .market__menu .market__link {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 0 5px;
    text-align: center;
  }
  .market__action:last-child {
    margin-left: auto;
  }
  .market__chart {
    height: 350px;
  }
  .market__chart_price {
    margin-top: 0;
    margin-left: -22px;
    margin-right: -12px;
  }
  .market__transactions {
    display: none;
  }
  .market__top {
    margin-bottom: 16px;
    padding: 0;
  }
  .market__title {
    margin-bottom: 16px;
  }
  .market__table {
    border: 1px solid #e4e4e4;
    border-radius: 20px;
  }
  .market__row:first-child .market__cell {
    border-top: none;
  }
  .market__cell {
    padding-left: 18px;
    text-align: center;
  }
  .market__cell:last-child {
    padding-right: 18px;
  }
  .dark .market__table {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .actions__fieldset {
    margin-bottom: 24px;
  }
  .dark .actions .select {
    height: 64px;
  }
  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: block !important;
  }
  .mobile-text-right {
    text-align: right !important;
  }
}
@media only screen and (max-width: 750px) {
  .lottie__money {
    display: none !important;
  }
  .lottie_money_2 {
    display: none !important;
  }
  .slider__item {
    background-image: linear-gradient(
        0deg,
        rgba(238, 124, 54, 0) 15%,
        rgba(238, 124, 54, 0.8) 61%
      ),
      url(../../assests/img/static-banner.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .slider__container {
    padding: 5px !important;
  }
}
@media only screen and (max-width: 600px) {
  .widgets__loader {
    margin-top: 0px;
  }
  .notifications__select {
    margin-top: 5px;
    width: 100%;
  }
  .lottie__money {
    display: none !important;
  }
  .lottie_money_2 {
    display: none !important;
  }
  /* .not__found {
    margin-top: 50%;
  } */
  /* .not__found__img {
    width: 90%;
    height: 200px;
    object-fit: contain;
  } */
  .no__list {
    height: auto;
    margin-top: 0px;
  }
  .forgot_password__innercontainer {
    padding: 0px;
  }
  .notification_popup_container {
    margin-top: 48px;
    padding: 2px 10px 2px 10px;
  }
  .notifications__popup .notifications__icon {
    display: none;
  }
  .popup_notification_body {
    margin-top: 42px;
    padding: 0px 0px 0px 0px;
  }
  .popup_notification_body.popup-text.p {
    max-height: 700px;
    overflow: scroll;
  }
  .widget__skeleton_container {
    grid-template-columns: repeat(1, 1fr) !important;
    justify-content: center;
  }
  .camera-btns {
    flex-direction: column;
  }
  .camera-btns > button {
    margin-bottom: 20px;
  }
  .trade_view__chart {
    height: 300px;
  }
  .verify__btns {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .verify__btns > * {
    margin-bottom: 12px;
  }
  .orders__cell:not(:first-child) {
    justify-content: space-evenly;
  }
  .profile__scratch-container {
    flex-direction: column;
  }
  .img_friends {
    padding: 50px;
    margin: 0px;
  }
  .top_notification {
    margin-left: 0px;
  }
  .top_container {
    position: relative;
    z-index: 0;
  }
  .no__scratch_card {
    margin-left: 0px !important;
  }
  .no__scratch_card img {
    margin-left: 0px !important;
  }
  .no__scratch_card .h6 {
    margin-left: 25% !important;
  }
  .referral__card .promotions__item {
    height: 266px !important;
    width: 80% !important;
  }
  .referral__card.promotions__list {
    display: grid;
    place-items: center;
  }
  .kyc__otp {
    max-width: 290px;
  }
  .kyc__otp input {
    min-width: 100% !important;
  }
}
