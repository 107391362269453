.login__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.login__col {
  padding: 80px;
}

.login__col:first-child {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 44%;
  flex: 0 0 44%;
  width: 44%;
  background: #ee7c36;
  color: #ffffff;
}

.login__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56%;
  flex: 0 0 56%;
  width: 56%;
  -ms-flex-item-align: center;
  align-self: center;
}

.login__logo {
  display: inline-block;
  margin-bottom: 25px;
}

.login__col:nth-child(2) .login__logo {
  display: none;
}

.login__title {
  margin-bottom: 16px;
  font-size: 40px;
}

.login__preview {
  /* position: absolute;
  top: 270px;
  left: -370px;
  width: 900px;
  pointer-events: none; */

  position: absolute;
  /* top: 539px; */
  left: -192px;
  width: 1009px;
  bottom: 0px;
  pointer-events: none;
}

.login__preview.large {
  width: 1309px;
  left: -370px;
}

.login__preview img {
  width: 100%;
}

.login__form {
  position: relative;
  z-index: 3;
  max-width: 412px;
  margin: auto;
}

.login__stage {
  margin-bottom: 48px;
}

.login__field:not(:last-child) {
  margin-bottom: 16px;
}

.login__links:not(:last-child) {
  margin-bottom: 30px;
}

.login__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: #ee7c36;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.login__link .icon {
  margin-right: 15px;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.login__link i {
  margin-right: 10px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
  color: #000;
}
.dark .login__link i {
  color: #ffffff;
}

.login__link:hover {
  color: #11142d;
}

.login__verify {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-bottom: 40px;
  border-radius: 12px;
  border: 2px solid #e4e4e4;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.login__verify .icon {
  margin-left: 9px;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.login__verify:hover {
  background: #e4e4e4;
}

.login__verify.success {
  border-color: #4fbf67;
  background: rgba(79, 191, 103, 0.1);
  color: #4fbf67;
}

.login__verify.success .icon {
  fill: #4fbf67;
}

.login__status {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 32px;
  height: 32px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  border: 1px solid #ee7c36;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.login__status:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #ee7c36;
  border-radius: 50%;
}

.login__verify.success .login__status {
  border-color: #4fbf67;
  background: #4fbf67
    url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6 8.60001L2.4 5.40001L0 7.80001L5.6 13.4L16 3.00001L13.6 0.600006L5.6 8.60001Z' fill='%23ffffff'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/16px auto;
}

.login__verify.success .login__status:before {
  display: none;
}

.login__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
}

.login__text {
  font-size: 13px;
  font-weight: 700;
}

.login__flex .login__text {
  margin-right: 12px;
}

.login__note {
  margin-bottom: 32px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33333;
  color: #808191;
}

.login__note a {
  color: #ee7c36;
}

.login__note a:hover {
  text-decoration: underline;
}

.dark .login__link .icon {
  fill: #ffffff;
}

.dark .login__link:hover {
  color: #ee7c36;
}

.dark .login__link:hover .icon {
  fill: #ee7c36;
}

.dark .login__verify {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

.dark .login__verify .icon {
  fill: #ffffff;
}

.dark .login__verify:hover {
  border-color: transparent;
  background: rgba(228, 228, 228, 0.1);
}

.dark .login__verify.success {
  border-color: #4fbf67;
  color: #4fbf67;
}

.dark .login__verify.success .icon {
  fill: #4fbf67;
}

.icon.icon-label {
  width: 12px;
  margin-top: -10px;
}

.react-multiple-carousel__arrow {
  z-index: 0 !important;
}

.forgot_password__container {
  padding: 10px;
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
}

.forgot_password__heading {
  text-align: center;
  margin-bottom: 20px;
}

.forgot_password__innercontainer {
  padding: 0px 50px 0px 50px;
}

.google__btn span {
  font-family: 'Poppins', sans-serif !important;
}

.profile__scratch {
  margin: 30px 10px 10px 10px;
  text-align: center;
}

.profile__scratch-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.profile__scratch-container img {
  width: 200px;
  margin-right: 12px;
  cursor: pointer;
}

.profile__scratch-popup.mw-700 .popup_settings {
  max-width: 759px;
}

.profile__scratch-popup.mw-500 .popup_settings {
  max-width: 500px;
}

.profile__scratch-container div:hover {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
  cursor: pointer;
}

.profile__scratch-container .shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

.inital-scratch-card-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.inital-scratch-card-popup img {
  width: 320px;
}

.inital-scratch-card-popup .h5 {
  margin-bottom: 10px;
}

.inital-scratch-card-popup .h6 span {
  color: #ee7c36;
}

.theme-toogler {
  position: fixed;
  top: 30px;
  right: 40px;
  cursor: pointer;
  box-shadow: 0 15px 35px rgb(0 0 0 / 7%);
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: all 0s;
}

.theme-toogler:hover {
  transform: scale(1.1);
}

.theme-toogler svg {
  width: 30px;
}

.dark .theme-toogler {
  background: #242731;
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .theme-toogler {
    bottom: 20px;
    right: 20px;
    top: revert !important;
    z-index: 3;
  }
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.twofa_otp_error_container {
  background-color: rgba(238, 124, 54, 0.2);
  border-radius: 10px;
  padding: 32px 15px 20px 15px;
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-size: 11px;
  position: relative;
  text-align: center;
  border: 1px solid #ee7c36;
}

.twofa_otp_error_container i {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 15px;
  cursor: pointer;
}

.twofa_otp_error_container a {
  color: #ee7c36;
}
