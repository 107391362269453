.card {
  position: relative;
}

.card__next {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.146143);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.146143);
}

.card__next .icon {
  font-size: 10px;
  fill: #11142d;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.card__next:hover .icon {
  fill: #ee7c36;
}

.card__chart_total-balance {
  height: 220px;
  margin-top: -15px;
}

.card__inner .card__title {
  margin-bottom: 16px;
}

.card__currency {
  display: inline-block;
  margin-bottom: 3px;
  padding: 0 11px;
  border-radius: 13px;
  background: #ff9f38;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: #ffffff;
}

.card__inner .card__price {
  margin-bottom: 32px;
}

.card__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.card__head_mb32 {
  margin-bottom: 32px;
}

.card__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
}

.card__icon,
.card__arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.card__icon {
  border-radius: 50%;
}

.card__arrow .icon {
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
  width: 20px;
}

.card__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;
}

.card__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 4px;
}

.card__category {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.card__date {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.card__status {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

.card__item .card__price {
  font-weight: 600;
}

.card__view {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 26px;
  font-weight: 600;
  color: #ee7c36;
}

.card__view .icon {
  fill: #11142d;
}

.card__view:hover .icon {
  fill: #ee7c36;
}

.card__filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.card__filters .checkbox:not(:last-child) {
  margin-bottom: 20px;
}

.card__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -4px;
}

.card__btns_mt40 {
  margin-top: 40px;
}

.card__btns .card__btn {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: auto;
  margin: 0 4px;
  padding: 0;
}

.card__reset {
  display: block;
  margin-top: 24px;
  border-top: 1px solid #e4e4e4;
  padding-top: 24px;
  line-height: 1.42857;
  font-weight: 700;
  color: #808191;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.card__reset:hover {
  color: #ee7c36;
}

.card_widget {
  padding: 32px;
  border-radius: 24px;
  background: #ffffff;
}

.card_widget:before,
.card_widget:after {
  content: '';
  position: absolute;
  border-radius: 24px;
}

.card_widget:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
}

.card_widget:after {
  top: 32px;
  right: 32px;
  left: 32px;
  bottom: -32px;
  z-index: -2;
  background: #e4e4e4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.card:not(:last-child) {
  margin-bottom: 98px;
}

.dark .card__next {
  background: #242731;
}

.dark .card__next .icon {
  fill: #ffffff;
}

.dark .card__next:hover .icon {
  fill: #ee7c36;
}

.dark .card__details {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .card__view .icon {
  fill: #ffffff;
}

.dark .card__view:hover .icon {
  fill: #ee7c36;
}

.dark .card__reset {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .card_widget {
  background: #242731;
}

.dark .card_widget:before {
  background: rgba(36, 39, 49, 0.5);
}

.dark .card_widget:after {
  display: none;
}
