.header {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 48px 64px 36px;
}

.header__search {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 210px;
  margin-right: auto;
}

.header__input {
  width: 100%;
  height: 48px;
  padding-right: 32px;
  background: none;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
}

.header__input::-webkit-input-placeholder {
  color: #808191;
}

.header__input::-moz-placeholder {
  color: #808191;
}

.header__input::-ms-input-placeholder {
  color: #808191;
}

.header__input::placeholder {
  color: #808191;
}

.header__start {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24px;
  font-size: 0;
}

.header__start .icon {
  font-size: 20px;
  fill: #11142d;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.header__start:hover .icon {
  fill: #ee7c36;
}

.header__logo {
  display: none;
  margin-right: auto;
}

.header__logo img {
  width: 60px;
}

.header__logo.hidden {
  visibility: hidden;
  opacity: 0;
}

.header__group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.header__indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 40px;
  padding: 0 12px 0 6px;
  background: #ee7c36;
  border-radius: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #ffffff;
}

.header__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 4px 7px rgba(17, 20, 45, 0.402414);
  box-shadow: 0px 4px 7px rgba(17, 20, 45, 0.402414);
  background: #ffffff;
}

.header__item {
  position: relative;
}

.custom_selecter {
  position: relative;
}

.header__item_notifications {
  margin-right: 40px;
  font-size: 0;
}

.header__item_download {
  margin-right: 22px;
  font-size: 0;
}

.header__item_notifications .header__head,
.header__item_download .header__head {
  width: 24px;
  height: 24px;
  font-size: 0;
}

.header__item_notifications .header__head .icon,
.header__item_download .header__head .icon {
  font-size: 20px;
  fill: #808191;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.header__item_notifications .header__head:hover .icon,
.header__item_download .header__head:hover .icon {
  fill: #ee7c36;
}

.header__item_lang .header__head {
  position: relative;
  padding-right: 42px;
  font-weight: 600;
}

.header__item_lang .header__head:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 12px;
  height: 7px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%2311142d'/%3E%3C/svg%3E")
    no-repeat 50% 50% / auto 100%;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.header__item_lang:hover .header__head:before {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.header__item_notifications .header__head {
  position: relative;
}

.header__item_notifications .header__head:before {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background: #ff6628;
  border-radius: 50%;
  opacity: 0;
}

.header__item_notifications .header__head.active:before {
  opacity: 1;
}

.header__body {
  position: absolute;
  background: #ffffff;
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.header__item:hover .header__body {
  visibility: visible;
  opacity: 1;
}

/* .header__item.active .header__body {
    visibility: visible;
    opacity: 1;
  } */

.custom_selecter.active .header__body {
  visibility: visible;
  opacity: 1;
}

.header__item_notifications .header__body {
  top: calc(100% + 15px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 375px;
  padding: 0 24px 32px;
  -webkit-box-shadow: 0 15px 30px rgba(17, 20, 45, 0.1);
  box-shadow: 0 15px 30px rgba(17, 20, 45, 0.1);
}

.header__item_download .header__body {
  top: calc(100% + 22px);
  left: 50%;
  width: 338px;
  padding: 32px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
  box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
}

.header__item_lang .header__body {
  top: calc(100% + 22px);
  right: -15px;
  width: 300px;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
  box-shadow: 0 5px 20px rgba(17, 20, 45, 0.07);
}

.header__notifications {
  margin-bottom: 24px;
  z-index: 1000000;
}

.header__notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
}

.header__notification:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.header__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border-radius: 50%;
  background: -webkit-linear-gradient(
    226.42deg,
    #86e1a0 12.52%,
    #4fbf67 75.73%
  );
  background: -o-linear-gradient(226.42deg, #86e1a0 12.52%, #4fbf67 75.73%);
  background: linear-gradient(223.58deg, #86e1a0 12.52%, #4fbf67 75.73%);
}

.header__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.header__info {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}

.header__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header__time {
  font-size: 14px;
  font-weight: 600;
  color: #808191;
}

.header__status {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  background: #ee7c36;
  border-radius: 50%;
}

.header__btn {
  font-weight: 700;
}

.header__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header__col:first-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 40px;
}

.header__col:nth-child(2) {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 94px;
}

.header__category {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
}

.header__download {
  display: block;
}

.header__download:not(:last-child) {
  margin-bottom: 12px;
}

.header__lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header__cell {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  padding: 24px 22px;
}

.header__cell:first-child {
  border-right: 1px solid #e4e4e4;
}

.header__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.header__link:hover,
.header__link.active {
  color: #ee7c36;
}

.header__link:not(:last-child) {
  margin-bottom: 12px;
}

.header__flag {
  margin-right: 16px;
}

.header__user {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  margin-left: 36px;
}

.header__user img {
  min-width: 100%;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.header__toggle {
  display: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: 32px;
}

.header_border:before {
  content: '';
  position: absolute;
  top: 0;
  right: 425px;
  bottom: 0;
  width: 1px;
  background: #e4e4e4;
}

.dark .header__input {
  color: #ffffff;
}

.dark .header__start .icon {
  fill: #ffffff;
}

.dark .header__start:hover .icon {
  fill: #ee7c36;
}

.dark .header__body {
  background: #242731;
}

.dark .header__item_notifications .header__body {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .header__notification {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .header__item_lang .header__head {
  color: #ffffff;
}

.dark .header__item_lang .header__head:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none' viewBox='0 0 12 8'%3E%3Cpath d='M10.281.305L5.988 4.598 1.695.305A1 1 0 0 0 .293.317a1 1 0 0 0-.012 1.402l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-.441-1.687 1 1 0 0 0-.973.273z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.dark .header__item_lang .header__body {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .header__cell:first-child {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .header__link:hover {
  color: #ffffff;
}

.dark .header__link.active {
  color: #ee7c36;
}

.dark .header__item_download .header__body {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .header__toggle svg > path {
  stroke: #ffffff;
}

.dark .header__toggle svg g {
  stroke: rgba(128, 129, 145, 0.7);
}

.dark .header__toggle svg g path:nth-child(2) {
  opacity: 0.6;
}

.dark .header_border:before {
  background: rgba(228, 228, 228, 0.1);
}

.kyc_notification {
  font-family: 'Poppins', sans-serif;
  max-width: 300px;
  margin-left: -142px;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 8px 25px rgb(0 0 0 / 7%);
}

.dark .kyc_notification {
  background-color: #242731;
}
