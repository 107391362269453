.kyc__otp > * {
  margin-bottom: 15px;
}

.close-btn {
  background-color: rgb(218, 82, 82);
}

.close-btn:hover {
  background-color: rgb(247, 114, 114);
}

.green-btn {
  background-color: rgb(69, 199, 126);
}

.green-btn:hover {
  background-color: rgb(41, 128, 79);
}

.camera-btns {
  display: flex;
  margin-top: 30px;
}

.green-outline {
  border: 1px solid rgb(69, 199, 126);
}
.blue-outline {
  border: 1px solid #ee7c36;
}
.red-outline {
  border: 1px solid rgb(247, 114, 114);
}
.icon-container {
  padding: 10px;
  border-radius: 40px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
}

.camera-actions {
  padding: 10px;
  margin-top: 12px;
  display: flex;
}

.why__kyc {
  color: #ee7c36;
  text-decoration: none;
}

.why__kyc:hover {
  color: #ee7c36;
  text-decoration: none;
}

.link_items:hover td {
  color: #ee7c36;
}

.link_items td {
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-btns > * {
  margin-right: 12px;
}

.camera-actions > * {
  margin-right: 12px;
}
