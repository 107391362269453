.details__container {
  position: relative;
  padding: 32px 32px 18px;
  background: #ffffff;
  border-radius: 32px;
  font-family: 'Poppins', sans-serif;
}

.details__container:before {
  content: '';
  position: absolute;
  top: 14px;
  left: 20px;
  right: 20px;
  bottom: -14px;
  z-index: -1;
  border-radius: 32px;
  background: #e4e4e4;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
}

.details__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.details__company {
  position: relative;
  z-index: 5;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 245px;
  margin-right: auto;
  cursor: pointer;
}

.details__head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 30px;
}

.details__logo {
  margin-right: 16px;
}

.details__logo img {
  width: 100%;
  min-height: 100%;
}

.details__head .details__logo {
  width: 56px;
  height: 56px;
}

.details__head .details__info {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__currency {
  color: #808191;
}

.details__head .details__currency {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__desc {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.details__body {
  position: absolute;
  top: calc(100% + 20px);
  left: -16px;
  right: -16px;
  padding: 16px;
  background: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* .details__body::-webkit-scrollbar {
  display: none;
}

.exchange__body::-webkit-scrollbar {
  display: none;
} */

.details__company.active .details__body {
  visibility: visible;
  opacity: 1;
  max-height: 200px;
  overflow-y: scroll;
}

.details__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 12px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  text-align: left;
  width: 100%;
}

.details__item:hover {
  background: rgba(228, 228, 228, 0.3);
}

.details__item:not(:last-child) {
  margin-bottom: 4px;
}

.details__item .details__logo {
  width: 36px;
  height: 36px;
}

.max-width-50 {
  max-width: 50%;
}

.details__item .details__info {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: #11142d;
}

.details__item .details__currency {
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.details__btns .details__btn:not(:last-child) {
  margin-right: 16px;
}

.details__burger {
  position: absolute;
  top: 6px;
  right: 0;
  z-index: 2;
  width: 20px;
  height: 20px;
}

.details__burger:before,
.details__burger:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  margin: 6px 0;
  border-radius: 1px;
  background: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.details__company.active .details__burger:before {
  -webkit-transform: translateY(4px) rotate(45deg);
  -ms-transform: translateY(4px) rotate(45deg);
  transform: translateY(4px) rotate(45deg);
}

.details__company.active .details__burger:after {
  -webkit-transform: translateY(-4px) rotate(-45deg);
  -ms-transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-4px) rotate(-45deg);
}

.details__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.details__money {
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -1px;
  font-weight: 600;
}

.details__status {
  margin-left: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__chart {
  height: 330px;
  margin: -32px -32px 24px -24px;
}

.details__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -32px 32px;
  border-top: 1px solid #e4e4e4;
}

.details__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  padding: 36px 28px;
  border-bottom: 1px solid #e4e4e4;
}

.details__box:nth-child(2n + 1) {
  border-right: 1px solid #e4e4e4;
}

.details__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
}

.details__category {
  margin-bottom: 3px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #808191;
}

.details__category .icon {
  position: relative;
  bottom: 1px;
  width: 15px;
  margin-left: 8px;
  font-size: 12px;
  fill: #808191;
}

.details__price {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.33333;
  font-weight: 500;
}

.details__title {
  margin-bottom: 24px;
}

.details__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
}

.details__col:first-child {
  /* max-width: 285px; */
  width: 100%;
  margin-right: 40px;
}

.details__text {
  font-size: 13px;
  line-height: 1.53846;
  color: #808191;
}

.details__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.details__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 1.53846;
  font-weight: 700;
  color: #ee7c36;
}

.details__link .icon {
  margin-right: 12px;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.details__link:hover .icon {
  fill: #ee7c36;
}

.details__link:not(:last-child) {
  margin-bottom: 16px;
}

.dark .details__container {
  background: #242731;
}

.dark .details__container:before {
  display: none;
}

.dark .details__burger:before,
.dark .details__burger:after {
  background: #ffffff;
}

.dark .details__chart .apexcharts-xaxis line,
.dark .details__chart .apexcharts-grid line {
  stroke: rgba(228, 228, 228, 0.1);
}

.dark .details__body {
  background: #191b20;
  border: 1px solid rgba(228, 228, 228, 0.1);
}

.dark .details__item:hover {
  background: #242731;
}

.dark .details__list,
.dark .details__box,
.dark .details__icon {
  border-color: rgba(228, 228, 228, 0.1);
}

.dark .details__item .details__info {
  color: #ffffff;
}

.dark .details__link .icon {
  fill: #ffffff;
}

.dark .details__link:hover .icon {
  fill: #ee7c36;
}

.apexcharts-tooltip-z-group {
  display: none !important;
}

.details__body::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.details__body::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px grey; */
  border-radius: 10px;
  margin: 8px;
}

/* Handle */
.dark .details__body::-webkit-scrollbar-thumb {
  background: rgb(7, 7, 7);
  border-radius: 10px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

.details__body::-webkit-scrollbar-thumb {
  background: rgb(90, 90, 90);
  border-radius: 10px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

/* Handle on hover */
.details__body::-webkit-scrollbar-thumb:hover {
  background: gray;
  border-radius: 10px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

.exchange__body::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.exchange__body::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px grey; */
  border-radius: 10px;
  margin: 8px;
}

/* Handle */
.dark .exchange__body::-webkit-scrollbar-thumb {
  background: rgb(7, 7, 7);
  border-radius: 10px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

.exchange__body::-webkit-scrollbar-thumb {
  background: rgb(90, 90, 90);
  border-radius: 10px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

/* Handle on hover */
.exchange__body::-webkit-scrollbar-thumb:hover {
  background: gray;
  border-radius: 10px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

.share__container {
  margin: 20px 10px;
}

.share__coins {
  text-align: center;
  margin-bottom: 22px;
}

.share__coins .h6 {
  font-size: 12px;
  color: #808191;
  margin-top: 12px;
}

.share__container img {
  height: 80px;
  margin-bottom: 22px;
}

.share__popup .popup_settings {
  max-width: 420px;
  overflow: hidden;
}

.share__social_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.share__social_icon > a {
  display: grid;
  place-items: center;
  border-radius: 50%;
  text-decoration: none;
  color: white;
  padding: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: all 0.2s;
}

.share__social_icon > a:hover {
  transform: scale(1.2);
}

.share__social_icon a i {
  font-size: 24px;
  color: white;
}

.share__copy_link {
  font-size: 12px;
  margin-left: 4px;
  margin-bottom: 5px;
}

.share__coins span {
  color: #ee7c36;
}

.share__bg {
  position: absolute;
  background-color: #ee7c36;
  height: 100px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.f-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
