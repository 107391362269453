.dropdown {
  position: relative;
  font-size: 0;
}

.dropdown__head {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.dropdown__head .icon {
  font-size: 12px;
  fill: #11142d;
  width: 15px;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.dropdown__head i {
  font-size: 12px;
  color: #11142d;
  -webkit-transition: fill 0.2s;
  -o-transition: fill 0.2s;
  transition: fill 0.2s;
}

.dropdown__head:hover .icon {
  fill: #ee7c36;
}

.dropdown__head:hover i {
  color: #ee7c36;
}

.dropdown__body {
  position: absolute;
  top: calc(100% + 13px);
  right: -16px;
  z-index: 2;
  min-width: 192px;
  padding: 24px;
  border: 1px solid #e4e4e4;
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
}

.dropdown__link {
  position: relative;
  display: block;
  padding-left: 28px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.dropdown__link:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 13px;
  background: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.6 8.60001L2.4 5.40001L0 7.80001L5.6 13.4L16 3.00001L13.6 0.600006L5.6 8.60001Z' fill='%23355DFF'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/100% auto;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.dropdown__link:hover {
  color: #11142d;
}

.dropdown__link.selected {
  color: #ee7c36;
}

.dropdown__link.selected:before {
  opacity: 1;
}

.dropdown__link:not(:last-child) {
  margin-bottom: 8px;
}

.dropdown.active {
  z-index: 5;
}

.dropdown.active .dropdown__head .icon {
  fill: #ee7c36;
}

.dropdown.active .dropdown__body {
  visibility: visible;
  opacity: 1;
}

.dark .dropdown__head {
  background: #242731;
}

.dark .dropdown__head .icon {
  fill: #ffffff;
}

.dark .dropdown__head:hover .icon {
  fill: #ee7c36;
}

.dark .dropdown__head i {
  color: #ffffff;
}

.dark .dropdown__head:hover i {
  color: #ee7c36;
}

.dark .dropdown__body {
  border-color: rgba(228, 228, 228, 0.1);
  background: #191b20;
}

.dark .dropdown__link:hover {
  color: #ffffff;
}

.dark .dropdown__link.selected {
  color: #ee7c36;
}
