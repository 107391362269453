.dark .referral__code .balances__cell,
.dark .referral__code .balances__price {
  color: white;
}

.referral_col-2 .h6 {
  font-size: 16px;
  text-align: center;
}

.referral-col-2-container {
  margin-top: 20px;
  display: grid;
}

.referral-col-2-container button {
  margin-top: 40px;
}

.img_friends {
  margin: 20px;
}

.download__text {
  text-align: center;
  margin-top: 20px;
  color: #808191;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.referral__popup .popup_settings {
  max-width: 450px;
}

.referral__popup_container {
  text-align: center;
  margin: 35px 10px;
}

.referral__popup_container .p {
  margin-top: 12px;
  color: #808191;
}

.instructions__link {
  color: #ee7c36;
  text-decoration: none;
}

.referral__card .promotions__item img {
  width: 130%;
}

.referral__card .promotions__item {
  background-position: center;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 15px;
}

.referral__card .promotions__item.blue {
  background-color: #4262f1;
}

.promotions__item .p {
  color: #ee7c36;
  font-family: 'Poppins', sans-serif;
  font-family: 12px;
}

.promotions__item.unredeemed {
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotions__item.unredeemed img {
  width: 80px;
  margin: 18px;
}
.scratch-card-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
}

.scratch-card-popup img {
  width: 320px;
}

.scratch-card-popup .h5 {
  margin-bottom: 10px;
}

.scratch-card-popup .h6 span {
  color: #ee7c36;
}

.referral_share > * {
  margin-right: 12px;
}

.referral__share_popup .popup_settings {
  padding: 0px 32px;
}

.bg-transparent {
  background: transparent !important;
  box-shadow: none;
}

.no__scratch_card {
  margin-left: 40%;
  margin-top: 50px;
}

.no__scratch_card img {
  margin-left: -52px;
  margin-bottom: 42px;
  width: 300px;
}
